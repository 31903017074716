// Main SASS file (compiled by Jekyll)

// Define special two-line foundation topbar label class:
//   - we need foundation's topbar-height parameter for scaling
//   - subtract 2rem (1rem per line of label text) to get required padding
//   - then divide the padding by two to split between top and bottom
@import 'foundation/settings';
@import 'foundation/components/global';
$tb-unit: ( $topbar-height - 2rem ) / 2;
//
// Use this class in foundation's topbar link-element (a)
// for two-line menu labels (A.Chamberlin)
.two-line-menu {
  line-height: 1rem ! important;
  padding-top: $tb-unit ! important;
  padding-bottom: $tb-unit ! important;
}

#neo_title {
  font-weight: 200;
}

// Custom alert boxes for site messages (A.Chamberin)
.site-wide-alert {
  font-size: 1.2rem;
  text-align: center;
}
.home-page-alert {
  font-size: 1.0rem;
  text-align: center;
}
.scout-alert {
  font-size: 1.0rem;
  text-align: center;
}
.medium-alert-font {
  font-size: 1.0rem;
}

// Support for "Skip Navigation" links (accessibility requirement, A.Chamberlin):
// (from JPL home page CSS)
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto
}

// Try to get columns in a row to align veritically to the middle (A.Chamberlin):
// (See: http://foundation.zurb.com/forum/posts/526-how-to-center-grid-row-vertically)
// NOTE: this breaks normal responsive behavior!
.valign-middle {
  display: table;
}
.valign-middle .columns {
  display: table-cell;
  vertical-align: middle;
}
.valign-middle .columns,
.valign-middle [class*="column"] + [class*="column"]:last-child {
  float: none;
}
@media #{$medium-up} {
  .valign-center-not-small {
    position: absolute;
    top: 30%;
    left: 36%;
    width: 40%;
    transform: translateY(-30%);
    transform: translateX(-36%);
  }
}
.valign-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// Try to get columns in a row to align veritically to the bottom (A.Chamberlin):
// NOTE: this breaks normal responsive behavior!
.valign-bottom {
  display: table;
}
.valign-bottom .columns {
  display: table-cell;
  vertical-align: bottom;
}
.valign-bottom .columns,
.valign-bottom [class*="column"] + [class*="column"]:last-child {
  float: none;
}

// Site-wide Banner (A.Chamberin):
$banner_breakpoint_medium: 820px;
$banner_breakpoint_small: 780px;
$banner_breakpoint_mobile: 650px;
$banner_breakpoint_tiny: 530px;
.banner-row {
  position: relative;
  background-color: black;
}
img.overlay-trilogo {
  height: 42px;
  //max-width: none;
  //border: 1px solid yellow;
  position: absolute;
  left: 10px;
  top: 10px;
  @media all and (max-width: $banner_breakpoint_tiny) {
    height: 36px;
    top: 3px;
    clip: rect(0px,44px,36px,0px);
  }
  @media all and (min-width: $banner_breakpoint_tiny) and (max-width: $banner_breakpoint_small) {
    //overflow: hidden;
    clip: rect(0px,50px,42px,0px);
    //border: 1px solid yellow;
  }
}
img.overlay-cneos {
  position: absolute;
  max-width: none;
  height: 28px;
  left: 70px;
  top: 6px;
  @media all and (min-width: $banner_breakpoint_tiny) and (max-width: $banner_breakpoint_small) {
    height: 40px;
    //border: 1px solid green;
    left: 70px;
    top: 10px;
  }
  @media all and (min-width: $banner_breakpoint_small) {
    height: 40px;
    //border: 1px solid yellow;
    left: 265px;
    top: 10px;
  }
}
img.overlay-cneos-stacked {
  position: absolute;
  max-width: none;
  height: 40px;
  left: 74px;
  top: 10px;
  display: none;
  @media (min-width: $banner_breakpoint_tiny) {
    display: none;
  }
  @media all and (min-width: $banner_breakpoint_mobile) and (max-width: $banner_breakpoint_small) {
    height: 40px;
    left: 267px;
    top: 10px;
    display: block;
  }
  @media all and (min-width: $banner_breakpoint_small) and (max-width: $banner_breakpoint_medium) {
    display: none;
  }
  @media all and (min-width: $banner_breakpoint_medium) {
    height: 40px;
    left: 462px;
    top: 10px;
    display: block;
  }
}
// Banner links:
.link-container {
  //display: none;
}
#link_nasa {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 42px;
  width: 50px;
  //border: 1px solid yellow;
  @media (max-width: $banner_breakpoint_tiny) {
    top: 3px;
    left: 10px;
    height: 36px;
    width: 44px;
    //border: 1px solid orange;
  }
}
#link_jpl {
  position: absolute;
  top: 17px;
  left: 62px;
  height: 16px;
  width: 173px;
  //border: 1px solid yellow;
  @media all and (max-width: $banner_breakpoint_small) {
    display: none;
  }
}
#link_caltech {
  position: absolute;
  top: 33px;
  left: 62px;
  height: 15px;
  width: 173px;
  //border: 1px solid yellow;
  @media all and (max-width: $banner_breakpoint_small) {
    display: none;
  }
}
#link_cneos {
  position: absolute;
  top: 6px;
  left: 70px;
  height: 28px;
  width: 132px;
  //border: 1px solid yellow;
  @media all and (min-width: $banner_breakpoint_tiny) and (max-width: $banner_breakpoint_mobile) {
    top: 10px;
    left: 70px;
    height: 40px;
    width: 188px;
  }
  @media all and (min-width: $banner_breakpoint_mobile) and (max-width: $banner_breakpoint_small) {
    top: 10px;
    left: 70px;
    height: 40px;
    width: 335px;
  }
  @media all and (min-width: $banner_breakpoint_small) and (max-width: $banner_breakpoint_medium) {
    top: 10px;
    left: 265px;
    height: 40px;
    width: 188px;
  }
  @media all and (min-width: $banner_breakpoint_medium) {
    top: 10px;
    left: 265px;
    height: 40px;
    width: 335px;
  }
}

// PDC alert background color links
$alert-link-color: yellow;
.alert-link:link { color: $alert-link-color; }
.alert-link:visited { color: $alert-link-color; }
.alert-link:hover { color: darken($alert-link-color, 10%); }

// FAQ page anchors (A.Chamberlin):
$top-nav-with-breadcrumbs-height: 74px;
$faq-anchor-head-height: 10px + $top-nav-with-breadcrumbs-height;
.faq-anchor {
  display: block;
  position: relative;
  top: -$faq-anchor-head-height;
  visibility: hidden;
}
.faq-content {
  display: none;
}

// Glossary side-nav div container:
.sidebar {
  background-color: $ghost;
  padding: 20px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: $gainsboro;
  //border-color: #C8C9CB;
  border-radius: 3px;
}

// Glossary term title:
.glossary-term {
  border-bottom: 2px solid $silver;
  margin-bottom: 1.0rem;
  margin-top: 0.0rem;
}

// Attempt to append an external-link icon after all external links (A.Chamberlin):
// Optionally hide this link is the "hide-ext-link" class is set.
a[href^="http"]:not(.hide-ext-link):after {
  margin: 0 0 0 5px;
  content: "\f08e";
  font-family: FontAwesome;
  font-size: 60%;
}
// NOTE: this does not work!
//a[href^="http"]:after + img {
//  content: none;
//}

// Attempt to prevent automatic image resizing with browser size (A.Chamberlin):
// WARNING: this can cause overlapping of Foundation column/grid content when resizing
img.no-resize {
  max-width: none;
}

img.fixwidth {
  width: 650px !important;
}

// Try to change the dataTables hover background color (A.Chamberlin):
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: rgba(255, 255, 244, 1.00);
}
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: rgba(243, 243, 234, 1.00);
}

// dataTables "dom" element formatting:
#dtdom-left {
  float: left;
}
#dtdom-right {
  float: right;
}

// dataTables button notes:
div#dt-button-notes {
  background-color: #F5F5F5;
  border: solid 1px #DDDDDD;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
div#dt-button-notes p {
  font-size: 0.8rem;
  margin-bottom: 0 !important;
}

// Add space above Sentry removed-objects table:
div#risk_removed_table_wrapper {
  margin-top: 2rem;
}

// Prevent tables with no-wrap content from overflowing their containing grid:
div.table-grid-container {
  overflow-x: scroll;
  border: 1px dotted rgba(0, 0, 0, 0.10);
}

// Center compact tables within their grid containter:
table.compact-table {
  margin-left: auto;
  margin-right: auto;
}

// Special class to make a table compact by removing most of the white-space (A.Chamberlin)
table.tight-table td,
table.tight-table th {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
// Special class to make a table slightly less compact than the above (A.Chamberlin)
table.compact-table td,
table.compact-table th {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
thead.table-title th {
  font-size: 1rem;
  padding: 0.5rem !important;
}
// thead.table-title tr {
  //background-color: #eee;
// }

// NEO Size Estimator form error format:
.ast-size-est {
  .error {
    color: white;
    background-color: red;
    border: 1px solid red;
  }
}

// Trying to group tables close-together in a row (A.Chamberlin):
.table-container {
  clear: both;
  overflow: hidden;
}
.table-container table {
  display: inline-block;
  vertical-align: top;
  margin: 1.25rem 2rem;
}
.table-footer-note {
  font-weight: normal;
  font-size: 0.7rem;
}

li.sitemap {
  padding-left: 2rem;
  padding-right: 2rem;
}

// Torino-Scale Table
table.TS {
  border: 1px solid black;
}
table.TS td.TS-label {
  font-size: 1.0rem;
  font-weight: bold;
  white-space: nowrap;
  border-top: 1px solid black;
}
table.TS td.TS-value {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  border-left: 1px solid black;
  border-top: 1px solid black;
}
table.TS td.TS-desc {
  font-size: 1.0rem;
  font-weight: normal;
  border-left: 1px solid black;
  border-top: 1px solid black;
}
table.TS tr.TS-white { background-color: white; }
table.TS tr.TS-green { background-color: green; }
table.TS tr.TS-yellow { background-color: yellow; }
table.TS tr.TS-orange { background-color: orange; }
table.TS tr.TS-red { background-color: red; }

// NEA Size Estimator - Table
$ast_size_est_ahead_color: #EEFFEE;
$ast_size_est_hhead_color: #E5F4FD;
$ast_size_est_hover_color: #EEA;
table.ast-size-est {
  border: 1px solid black;
}
table.ast-size-est tr:hover
{
  background-color: $ast_size_est_hover_color;
}
table.ast-size-est th,
table.ast-size-est td {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
table.ast-size-est th {
  background-color: $ast_size_est_hhead_color;
}
table.ast-size-est td,
tr.ast-size-est-hhead th,
th.ast-size-est-hhead {
  text-align: center;
}
tr.ast-size-est-ahead th {
  background-color: $ast_size_est_ahead_color;
}
.ast-size-est-hhead,
th.ast-size-est-hhead {
  background-color: $ast_size_est_hhead_color !important;
}
//td.ast-size-est-hhead {
//  font-weight: bold;
//}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}

// Front-page NEO close-approach styling (A.Chamberlin)
.next-ca-table {
  margin-bottom: 0px;
}
table.next-ca-table tbody td {
  padding: 4px;
}
.ca-see-more {
  font-size: 0.8rem;
  margin-top:   0.0rem;
  margin-left:  1.0rem;
  margin-right: 1.0rem;
}

// Front-page NEA stats styling (A.Chamberlin)
.nea-stats-table {
  margin-bottom: 0px;
}
table.nea-stats-table tbody td {
  padding: 4px;
}
.nea-stats-date {
  font-size: 1.0rem;
}

// Custom styling for the discovery stats numbers table (A.Chamberlin)
div.numbers_table {
  /*text-align: center;*/
  font-size: 0.75rem;
  height: 400px;
  overflow-y: scroll;
}

// Custom table styling for the NEOWISE "totals" table (A.Chamberlin)
th.wise-totals {
  //background-color: #2ba6cb;
  background-color: #e9e9e9;
  font-size: normal;
  color: black;
  padding: 4px;
}
td.wise-totals {
  border: 1px solid lightgray;
  padding: 4px;
  text-align: right;
}
td.wise-totals-left {
  border: 1px solid lightgray;
  text-align: left;
  white-space: nowrap;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
}
tr.wise-total {
  border: 1px solid lightgray;
  background-color: #eee;
  color: black;
  font-weight: bold;
}

.executive-summary {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  span {
    //color: #ed872d;
    //color: #cc6600;
    //color: darkorange;
    //color: darkviolet;
    font-weight: 300;
    font-size: 1.25rem;
  }
  hr {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
#neo_main_panel {
  //border-left: 1px solid $iron;
}
.top-news-panel {
  //padding-top: 2rem;
  @media #{$medium-up} {
    padding-left: 1rem;
    padding-right: 1rem;
    //border-left: 1px solid $iron;
    //border-top: 1px solid $iron;
    //border-bottom: 1px solid $iron;
    background-color: $ghost;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  @media #{$small-only} {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.news-heading-main-page {
  //background-color: $ghost;
  //background-color: $oil;
  font-size: 1.5rem;
  //color: white;
  //padding-left: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.3rem;
}

// Spotlight Topics container (A.Chamberlin)
.spotlight-topics {
  //background-color: #43AC6A;  // sea-green (from Foundation "success-color")
  //background-color: #e2fbeb;  // light-green (manually from color picker)
  //background-color: #fbfbe2;  // light-yellow (manually from color picker)
  //background-color: #008CAA;  // default links color
  //background-color: #bbdff4;
  //background-color: #1779ba;
  //background-color: #4e8ed1;  // from banner (I think I like this one)
  //background-color: #add9f1;
  padding-left: 1rem;
  padding-right: 1rem;
  //padding-top: 1rem;
  @media #{$medium-up} {
    //border: 3px dashed $oil;
    //border: 2px solid $oil;
    //box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    //border-bottom: 1px solid $steel;
    //margin-bottom: 3px !important;
    //margin-bottom: 0.75rem !important;
    //margin-bottom: 1.0rem !important;
    margin-bottom: 0.25rem !important;
    //margin-top: 1.0rem !important;
    margin-top: 0px;
  }
}
.spotlight-topics li {
  padding-top: 10px;
  padding-bottom: 10px;
}
.spotlight-topics h5 {
  //color: white;
}

#neo_quick_links {
  margin-top: 8px;
}

@import "foundation/components/panels";
.custom-panel {
  @include panel(
    // Sets the panel background color. Default: $panel-pg
    $bg:#2B3D51,
    // Sets the panel padding amount. Default: $panel-padding
    $padding:20px,
    // Sets the font color based on the darkness of the bg & resets header line-heights for panels. Default: $panel-header-adjust
    $adjust:true
  );
}
.top-news {
  padding-top: 1rem;
  border-top: 1px solid $iron;
}
.top-news-archive {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
//.top-news p {
//  margin-bottom: 0.5rem;
//}
.top-news-line {
  border-width: 2px;
}
//.read-more {
//  margin-bottom: 1.25rem ! important;
//}

.news-date {
  color: $steel;
  font-size: 13px;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.media-contact {
  color: $steel;
  font-size: 12px;
}

.image-caption {
  color: $steel;
  font-size: 12px;
}

.video-caption {
  color: $steel;
  font-size: 12px;
  margin-bottom: 1.5em;
}

.sentry-notes tr {
  vertical-align: top;
}

.sentry-note-date {
  white-space: nowrap;
}

.no-wrap {
  white-space: nowrap;
}

.neo_settings_panel {
  padding-bottom: 0;
  //padding-top: 18px;
  padding-top: 8px;
}

.nhats-constraint-help {
  background-color: $ghost;
  border: 1px solid $iron;
  padding-top: 0.25rem;
  margin-bottom: 1.0rem;
  dl {
    margin-bottom: 0px;
  }
}

.nhats-details-tables {
  border: 1px solid $iron;
  max-width: none;
}
.nhdats-details-row3 {
  max-width: 600px;
  min-width: 470px;
  margin-bottom: 40px;
}
#traj_table {
  min-width: 450px;
  margin: auto;
}
#nhats_details_plot {
  width: 470px;
  margin: auto;
}

// NEO Close Approach:
.ca-status-panel {
  // border: 1px solid yellow;
}
.ca-filter[disabled] {
  cursor: not-allowed;
}
.ca-table-disable {
  cursor: not-allowed;
  pointer-events: none;
  filter: blur(1px);
  // background-color: yellow; // TESTING
}
.ca-disable-click {
  pointer-events: none;
}
.ca-disable-cursor {
  cursor: not-allowed;
}
.ca-press-the-button {
  font-weight: bold;
  color: darkviolet;
  // background-color: orange;
  // padding: 3px 1em 3px 1em;
  // margin-left: 1em;
}
.ca-status-message {
  font-weight: bold;
  background-color: lightblue;
  padding: 3px 1em 3px 1em;
}
.ca-table-busy {
  margin-bottom: 1em;
}
.ca-table-wait {
  // position: absolute;
  // z-index: 10000;
  // top: 5em;
  // left: 50%;
  // height: 100%;
  // width: 100%;
  // background-color: yellow;
  // background: yellow;
  // -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  // animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.ca-settings-link {
  margin-bottom: 0.5em;
}
.ca-button {
}
.ca-button[disabled] {
  cursor: not-allowed;
}


// Customize Scout filter (A.Chamberlin):
.scout-filter {
  background-color: $ghost;
  border: 1px solid $silver;
  margin-bottom: 8px;
}
.scout-filter-label {
  font-weight: bold;
}
.scout-filter-input {
  margin-bottom: 0px !important;
}
.scout-filter-submit {
  //margin-bottom: 10px !important;
}
.scout-filter-row {
  margin-top: 15px !important;
}
.scout-filter-instructions {
  font-size: 0.8rem !important;
  padding-left: 1.0rem;
}
.neo_scout_section {
  min-height: 320px !important;
}
.scout-object-label {
  font-family: monospace;
}
.scout-object-dropdown {
  max-height: 250px !important;
  overflow: scroll !important;
}
.scout-eph-object {
  font-size: 1.8rem;
}
.scout-eph-extra {
  font-size: 1.2rem;
  padding-left: 1.5rem;
}

// Special class to hide certain subnav items by default (e.g., "Object Details"):
.hide-subnav-item {
  display: none !important;
}

// Formatting for Fireballs citation/references (A.Chamberlin):
.fireballs-citation {
  font-size: 0.85rem;
  margin-left: 1.5rem;
}
.half-line-margin-after {
  margin-bottom: 0.5rem;
}

// Formatting for Fireballs chart (A.Chamberlin):
#fireballs-chart div {
  padding-bottom: 1rem;
}
.fireballs-download-panel {
  font-size: 0.75rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
}
.fireballs-limit-menu {
  margin-right: 1.0rem;
}

// Highcharts Menu Icon styling (A.Chamberlin):
.highcharts-menu-icon {
  border: 0;
  height: 27px;
  width: 29px;
  //vertical-align: middle;
}

// Prevent plotly.js charts from displaying the misleading
// "double click to zoom back out" message:
div.plotly-notifier {
  visibility: hidden;
}

// Nice alternative to Foundation "panels":
.custom-card {
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #272727;
  border-radius: 2px;
  margin-bottom: 20px;

  .title {
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    line-height: 3rem;
    font-size: 1.5rem;
    font-weight: 300;
    padding: 1.3rem;
    a {
      color: #ffab40;
      // float: right;
      // font-size: 0.8rem;
      margin-right: 1.3rem;
      transition: color 0.3s ease;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .table-title {
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    // line-height: 3rem;
    font-size: 1.5rem;
    font-weight: 300;
    padding: 0.7rem;
    a {
      color: #ffab40;
      // float: right;
      // font-size: 0.8rem;
      margin-right: 1.3rem;
      transition: color 0.3s ease;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
  .content {
    padding: 1.3rem;
    font-weight: 300;
    border-radius: 0 0 2px 2px;
  }
  p {
    margin: 0;
  }
  .action {
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 1.3rem;
  }
  .back {
    // color:#2E64FE;
    font-size: 0.8rem;
    margin-top:11px;
  }
  .showHideIcon {
    float:right;
  }
  .image {
    position: relative;
    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1.3rem;
      color: #fff;
    }
    img {
      border-radius: 2px 2px 0 0;
    }
  }
}
// Card-style for document abstracts:
.abstract-card {
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #272727;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

// API reference notice:
.api-ref {
  font-size: 1rem;
}

/* SPEICAL TAG FOR CONTACT-FORM HONEYPOT */
.for-special-guests {
  display: none;
}

/* CUSTOM NEO FOOTER SETTINGS */
.site-footer {
}
div.site-footer {
  //border-top: 3px solid $steel;
  background-color: $oil;  // use for white on black
  //background-color: black;
  color: $ghost;
  //color: $silver;
  //background-color: $snow;
//background-color: $ghost;  // use for black on white
  //background-color: white;
//color: black;
  margin-top: 15px;
  //padding-top: 1.0rem;
  //padding-left: 2.0rem;
  padding-left: 1.0rem;
  //padding-bottom: 1.0rem;

  //max-width: none;
  //border-top: 3px solid #000066;
  //border-top: 3px solid black;
  //border-top: 10px solid $oil;
  //border-bottom: 1px solid $silver;
  //$boxshadow: 0px -3px 5px 2px gray;
  //-webkit-box-shadow: $boxshadow;
  //-moz-box-shadow: $boxshadow;
  //box-shadow: $boxshadow;

  $footer-link-color: scale-color($primary-color, $lightness: 50%);
  $footer-link-hover-color: scale-color($footer-link-color, $lightness: 50%);
  a { color: $footer-link-color; }
  a:hover { color: $footer-link-hover-color; }
}
.cneos-logo {
  height: 100px;
  //background-color: gray;
}
.logo-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  //border: 1px solid orange;
}
.footer-links-container {
  padding-top: 1rem;
  //border: 1px solid orange;
}
.footer-credits-container {
  padding-top: 1rem;
  //border: 1px solid orange;
}
.footer-credits-list {
  line-height: 1.3;
}
.footer-inline-list {
  //display: table-cell !important;
  //display: block;
}
.footer-links li {
  padding-right: 1rem;
}
.footer-credits {
  font-size: 0.7rem;
  white-space: nowrap;
}
.footer-credits-title {
  font-size: 0.8rem;
  //font-weight: bold;
  //color: black;
  color: $silver;
}
.neo_sub_nav {
  // background-color: rgb(91, 192, 222);
  // border-radius: 4px;
  // padding-left: 10px;
  margin-bottom: 25px;
}
.neo_sub_nav.button-group > li {
    height: 2.4rem;
}
// .neo_sub_nav.button-group > li:first-child .button {
//     border-left: 1px solid;
//     border-color: rgba(255, 255, 255, 0.5);
// }
// .neo_sub_nav.button-group > li:last-child button, .button-group > li:last-child .button {
//     border-right: 0;
//     border-color: rgba(255, 255, 255, 0.5);
// }
.neo_sub_nav > li > button, .neo_sub_nav > li .button {
  padding: 8px 30px;
}
.neo_sub_nav li.active a {
    background-color: #2aaad0;
}
.breadcrumbs > * a {
  color: $steel;
}
ul.breadcrumbs {
  margin-bottom: 8px;  // default is 20px;
}

// News Archive Table (A.Chamberlin)
.news-excerpt-cell p {
  font-size: 0.75rem;
}

// Scout Ephemeris form label:
.bold-form-label {
  font-size: 1.2rem;
  font-weight: bold;
}

// All Datatable CSS

#apiErrorAlert {
  display: none;
}

/*****************
 * Datatable CSS */
select[name=closeApproachTable_length] {
  display: inline;
}
// Missing from foundation DT CSS
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

// Layout Table (because I need it for Sentry! A.Chamberlin)
tr.layout-table {
  background-color: #ecfaff !important;
}
td.layout-table {
  background-color: #ecfaff !important;
}

// NOTE: this section will probably go away since we'll use foundation's
//       "orbit" feature if we need such capability
//
/* Make the "slick" prev/next arrows visible on a white background */
.slick-prev:before, .slick-next:before {
  color: gray ! important;
}
.news-carousel {
  border: 1px solid gray;
}

// Image overlay controls for spotlight icons (A.Chamberlin):
$global-radius: 4px;
.icon-image {
  width: 100px;
  //height: 100px;
}
.image-wrapper {
  //width: 100%;
  //height: 100%;
  //border: 1px solid rgba(0,0,0,0.04);
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: $global-radius;
  //display: table;
}
.image-overlay-content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  text-align: center;
}
.overlay-fade-in {
  div {
    //letter-spacing: 0.15rem;
    //font-size: 0.2rem;
    //font-size: 1.8vw;
    //line-height: 1.2;
    //color: #f4f4f4;
    //color: #f2f2f2;
    //margin-top: 10%;
    //margin-left: 2%;
    //margin-right: 2%;
    //display: table-cell;
    //vertical-align: middle;
    //background: rgba(0,0,0,0.7);
    //opacity: 0;
    //transition: all 0.2s linear;
  }
  img {
    transition: all 0.2s linear;
  }
  .image-overlay-content {
    opacity: 0;
    color: $snow;
    background-color: $oil;
    //border: 1px solid yellow;
    //background-color: rgba(0,0,0,0.8);
    transition: all 0.4s ease-in-out;
    //display: inline-block;
    display: table-cell;
    vertical-align: middle;
    //font-size: 1.5vw !important;
    //font-size: 0.9rem !important;
    line-height: 1.2;
    //padding-top: 0.25rem;
    //margin-bottom: 1.0rem !important;
    //padding-left: 0.75rem;
    //padding-right: 0.75rem;
  }
  &:hover img {
    //transform: scale(1.2);
    //display: none;
    opacity: 0;
  }
  &:hover .image-overlay-content {
    opacity: 1;
    //color: black;
    //display: inline-block;
    //display: table-cell;
    //vertical-align: middle;
  }
  &:hover {
    //width: 100%;
  }
}

// Sicky side-nav using Magellan (A.Chamberlin):
// (NOTE: this is not currently working)
.magellan-scrollnav {
  width: 25%;
  [data-magellan-expedition], [data-magellan-expedition-clone] {
    width: 250px;
    min-width: 0;
  }
}

// Subnav Style (initial attempt: A.Chamberlin)
$include-html-nav-classes: $include-html-classes;
@import 'foundation/components/sub-nav';
.cneos-sub-nav {
  @include sub-nav();
  //background-color: yellow;
  margin-bottom: 0px;
  dd.active a {
    //border-color: $primary-color !important;
    //border: none;
    border-radius: 3px;
    //background-color: black !important;
  }
  dd {
    :not(.active) {
      //border: 1px solid $primary-color;
      //border: 1px solid $smoke;
      //border: 1px solid $gainsboro;
      border-radius: 3px;
      //background-color: $snow;
      background-color: $silver;
    }
    a &:hover {
      background-color: $primary-color;
    }
  }
  dd a {
    //color: $oil;
    color: $primary-color;
    &:hover {
      color: white;
      background-color: $primary-color;
      border-radius: 3px;
    }
  }
}

.horiz-scroll {
  overflow-x: auto;
}

// TESTING:
.disable {
  pointer-events: none;
  z-index: 1;
  position: absolute;
  background-color: lightgray;
  color: green;
}
